import { Mutation, Query } from "@apollo/client/react/components"
import React from "react"
import { Redirect, Route, Switch } from "react-router"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { PoolDetailsQuery, PoolDetailsQueryVariables } from "../../../../__generated__/PoolDetailsQuery"
import { UpsertPoolMutation, UpsertPoolMutationVariables } from "../../../../__generated__/UpsertPoolMutation"
import { stepToPath } from "../../../../common/pool-settings-setup"
import { TPoolRouteProps } from "../../../../routes.d"
import NotFound from "../../../Base/NotFound"
import ErrorView from "../../../components/ErrorView"
import LoadingView from "../../../components/LoadingView"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import Transitions, { slide } from "../../../components/Transitions"
import { entryHasManagerRole, extractPoolOgProps } from "../../../hooks/usePoolData"
import { isCurrentUserLoggedIn, placeholderEntry } from "../../../utils/data-utils"
import { getAuthSearch, getCreatePoolParams, withoutDomain } from "../../../utils/url-utils"
import { POOL_DETAILS_QUERY, UPSERT_POOL_MUTATION } from "../../queries"
import PoolSetupStepForm from "../components/PoolSetupStepForm"
import { stepMappingFor } from "../components/StepMappings"
import { IPoolSetupProps, TSwitchStep } from "../PoolSetupTypes.d"
import { PoolFormContainer, PoolFormTransitionContainer } from "../styles/CommonPoolSetup.styles"
import { poolCreateRegexp } from "../../../../common/url-utils"
import BracketPoolSetupCommon from "../components/BracketPoolSetupCommon"
import CreateBracketPoolForm from "../components/CreateBracketPoolForm"
import BracketPoolSettingsForm from "../components/BracketPoolSettings/BracketPoolSettingsForm"
import { buildDisclaimer } from "../../../../common/game-text"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { isNCAAWTournamentMatcher } from "../../../../common/common-utils-helpers"
import constants from "../../../../common/constants"
import { canUseDom } from "../../../utils/misc-utils"

const toggleEditActionName = "toggle-edit|"
const getPathPrefix = (match: any) => {
  const { path, params, url } = match
  const matcher = path.split("/:gameType")[0] + "/:gameType"
  const values = url.split(params.gameType)[0] + params.gameType
  return {
    matcher,
    values,
  }
}

function shouldGoToNextStepInUpdateFor(formStep: string) {
  return /round-bonus-settings|conferences/.test(formStep)
}

type RoutesType = TPoolRouteProps["routes"]
type RoutesPropType = {
  routes: RoutesType
}

class PoolSetup extends React.PureComponent<IPoolSetupProps & RoutesPropType> {
  public switchFormStep: TSwitchStep = (formStepOrHack, poolId, event, poolUrl, usePoolUrl) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    const { history, location, match, isUpdate, entry, gameInstanceUid, poolData } = this.props
    const stepMappings = stepMappingFor(gameInstanceUid)
    const parts = (formStepOrHack || "").split(toggleEditActionName)
    const attribute = parts.length > 1 ? parts[1] : null
    const foundStepMapping = (attribute && stepMappings.find(({ field }) => field === attribute)) || undefined
    const stepName = (foundStepMapping && foundStepMapping.path) || attribute
    const { deviceType } = poolData || {}
    const isMobile = deviceType === "handheld"
    const formStep = stepName || formStepOrHack
    const shouldGoToNextStepInUpdate = shouldGoToNextStepInUpdateFor(formStep || "")
    const isUpdateOrEditAndNextStep = isUpdate && shouldGoToNextStepInUpdate && !!formStep && !!stepMappings.find(({ path }) => path === formStep)
    const hasStep = stepName === "name" || !!foundStepMapping || isUpdateOrEditAndNextStep
    if (isUpdate && entry) {
      const basePath = withoutDomain(entry.pool.url || poolUrl || "")
      // return;
      if ((attribute || shouldGoToNextStepInUpdate) && hasStep) {
        history.push(`${basePath}/settings/attribute/${formStep}`)
      } else {
        history.push(`${basePath}/settings`)
      }
    } else {
      if (isMobile) {
        history.push(constants.CREATE_POOL_ROAD_BLOCK_PATH.replace("{POOL_ID}", poolId))
      } else {
        const vals = getCreatePoolParams(location.pathname, location.search)
        const isInFinilizedUpdateFlow = vals.hasFinalizedSetupFlag || hasStep
        const search = isInFinilizedUpdateFlow ? `?${vals.createPoolFinishedSetupSearchKey}=true` : ""
        // console.dir(vals)
        if (!isInFinilizedUpdateFlow && typeof window !== "undefined") {
          window.scrollTo(0, 0)
        }
        const parts2 = location.pathname.split("/pool/")
        // this prevents landing routes from breaking the flow (user arrives at /create-pool/?arg=this)
        const rootPart = parts2[0].replace(/\/$/, "")
        const shouldReturnToRecapInUpdateFlow =
          isInFinilizedUpdateFlow && vals.formStep && !shouldGoToNextStepInUpdate && !/recap|invite/.test(vals.formStep)
        const formStepOrRecap = shouldReturnToRecapInUpdateFlow ? "recap" : formStep
        if (formStepOrRecap) {
          const path = `${rootPart}/pool/${poolId}/step/${formStepOrRecap}${search}`
          const stepOrder = stepMappings.map(stepToPath)
          if (!stepOrder) {
            throw new Error("sfoiajwef")
          }
          if (stepOrder.indexOf(formStepOrRecap) === 0 || shouldReturnToRecapInUpdateFlow) {
            // NOTE qac: for the first step (name the pool), use replace so the back button doesnt take us there
            history.replace(path, slide.state)
          } else {
            history.push(path, slide.state)
          }
        } else {
          // steps are done!
          const isChallengeRoute = /\/challenge/.test(location.pathname)
          if (!isChallengeRoute) {
            const pUrl = usePoolUrl ? poolUrl || entry?.pool.url : entry?.pool.url || poolUrl
            if (pUrl) {
              history.push(withoutDomain(pUrl))
            } else {
              if (typeof window !== "undefined") {
                window.SH_ERROR_NOTIFY(new Error(`Didnt refetch query...`))
              }
              const url = `${getPathPrefix(match).values}/pools/${poolId}`
              window.location.replace(url)
            }
          }
        }
      }
    }
  }

  public onPoolMutationComplete = (response) => {
    const poolPeriodQuery = this.props.poolPeriodQuery as any
    const queryAssumesCustom = poolPeriodQuery.data?.gameInstance.period.usesEventGroups
    const newUsesCustom = response.upsertPool?.pool.poolSettings.gamesPerPeriod === "CUSTOM"
    const needToCheckForTab = queryAssumesCustom !== newUsesCustom
    if (poolPeriodQuery && poolPeriodQuery.refetch && needToCheckForTab) {
      poolPeriodQuery.refetch()
    }
  }

  public render() {
    const {
      location,
      history,
      entry,
      seasonId,
      match,
      gameType,
      seasonType,
      gameInstanceUid,
      year,
      isUpdate = false,
      canEdit = false,
      poolData,
    } = this.props

    const { pathname, search } = location
    const vals = getCreatePoolParams(pathname, search)
    const { hasFinalizedSetupFlag } = vals
    const poolId = vals.poolId || (entry && entry.pool && entry.pool.id) || this.props.poolId

    const { matcher } = getPathPrefix(match)
    const defaultParams = { gameType, seasonType, year }
    const { productSeason, deviceType } = poolData || {}
    const isMobile = deviceType === "handheld"
    const platform = isMobile ? "mweb" : "desktop"
    const application = isMobile ? "picksSportsAppWebview" : "picks"
    const variables = { poolId: poolId || "", platform, application }
    const createPoolPageDisclaimer = buildDisclaimer(gameInstanceUid || "", seasonType, productSeason?.productAbbrev || "", year)
    return (
      <ToggleNexusTheme isNexusStyles={isUpdate}>
        <Query<PoolDetailsQuery, PoolDetailsQueryVariables> skip={!variables.poolId} variables={variables} query={POOL_DETAILS_QUERY}>
          {(poolDetailQuery) => (
            <Mutation<UpsertPoolMutation, UpsertPoolMutationVariables>
              mutation={UPSERT_POOL_MUTATION}
              onCompleted={this.onPoolMutationComplete}
              awaitRefetchQueries
              refetchQueries={["CentralCurrentUsersEntriesQuery"]}
              fetchPolicy="no-cache"
            >
              {(upsertPoolMutation, { loading, error }) => {
                const pool = (poolDetailQuery.data && poolDetailQuery.data.pool) || null
                const stepMappings = stepMappingFor(gameInstanceUid)
                const stepOrder = stepMappings.map(stepToPath)
                const isReactivatedPool = !!pool && !!pool.parentPoolId
                if (isUpdate) {
                  if (!pool || !entry) {
                    return <LoadingView />
                  }

                  const hasManagerRole = entryHasManagerRole(entry)
                  if (!hasManagerRole) {
                    const basePath = withoutDomain(entry.pool.url || pool.url || "")
                    history.replace(basePath)
                  }

                  const classNames = [`fill-hack__${/attribute\/|\/settings/.test(location.pathname)}`, `is-update--${isUpdate}`, "is-bracket"]
                  return (
                    <PoolFormContainer className={classNames.join(" ")}>
                      <Switch location={location}>
                        <Route
                          exact={true}
                          path={`${matcher}/:poolId/settings`}
                          render={(_props) => {
                            if (loading) {
                              return <LoadingView />
                            }
                            return (
                              <BracketPoolSettingsForm
                                canEdit={canEdit}
                                history={this.props.history}
                                location={this.props.location}
                                match={this.props.match}
                                mutation={upsertPoolMutation}
                                pool={pool}
                                poolData={this.props.poolData}
                                routes={this.props.routes}
                                error={error}
                              />
                            )
                          }}
                        />
                        <Route
                          exact={true}
                          path={`${matcher}/pools/:poolId/settings`}
                          render={(_props) => {
                            if (loading) {
                              return <LoadingView />
                            }
                            return (
                              <BracketPoolSettingsForm
                                canEdit={canEdit}
                                history={this.props.history}
                                location={this.props.location}
                                match={this.props.match}
                                mutation={upsertPoolMutation}
                                pool={pool}
                                poolData={this.props.poolData}
                                routes={this.props.routes}
                                error={error}
                              />
                            )
                          }}
                        />
                      </Switch>
                      {error && <ErrorView error={error} />}
                    </PoolFormContainer>
                  )
                }
                const isInFlow = !isReactivatedPool && !hasFinalizedSetupFlag
                const usesAnimation = !this.props.useBodyScroll && isInFlow
                const isNcaaw = isNCAAWTournamentMatcher.test(gameInstanceUid ?? "")
                // Brackets pool setup
                const initialOpenInvites = (pool?.poolSettings.__typename === "PoolSettings" && pool.poolSettings.openInvites) || false
                return (
                  <PoolFormContainer className="white-bkg">
                    <PoolFormTransitionContainer className={`uses-animation--${usesAnimation}`}>
                      <Transitions pageKey={location.pathname} {...location.state} reversed={history.action === "POP"} noTransition={!usesAnimation}>
                        <Switch location={location}>
                          <Route
                            exact={true}
                            path={`${matcher}/create-pool`}
                            render={(_props) => (
                              <BracketPoolSetupCommon currentStep="info" steps={stepMappings} disclaimer={createPoolPageDisclaimer} isNcaaw={isNcaaw}>
                                <AnalyticScreen feature="brackets" subfeature={`create-pool`} title={`Create Pool`} deviceType={deviceType} />
                                <CreateBracketPoolForm
                                  switchStep={this.switchFormStep}
                                  mutation={upsertPoolMutation}
                                  nextStep={stepOrder ? stepOrder[1] : undefined}
                                  seasonId={seasonId}
                                  deviceType={deviceType}
                                  {...defaultParams}
                                />
                              </BracketPoolSetupCommon>
                            )}
                          />
                          <Route
                            exact={true}
                            path={`${matcher}/create-pool/pool/:poolId/step/name`}
                            render={(_props) => {
                              if (!pool || !entry) {
                                return <LoadingView />
                              }
                              return (
                                <BracketPoolSetupCommon
                                  steps={stepMappings}
                                  currentStep="info"
                                  disclaimer={createPoolPageDisclaimer}
                                  isNcaaw={isNcaaw}
                                >
                                  <CreateBracketPoolForm
                                    switchStep={this.switchFormStep}
                                    mutation={upsertPoolMutation}
                                    seasonId={seasonId}
                                    isUpdate={true}
                                    isInPromoVersion={true}
                                    poolId={pool.id}
                                    initialName={pool.name}
                                    initialUsesMagicLink={pool.usesMagicLink}
                                    initialOpenInvites={initialOpenInvites}
                                    initialSlogan={pool.slogan ?? undefined}
                                    initialPassword={pool.password}
                                    deviceType={deviceType}
                                    {...defaultParams}
                                  />
                                </BracketPoolSetupCommon>
                              )
                            }}
                          />
                          {stepOrder.map((stepName) => (
                            <Route
                              key={stepName}
                              exact={true}
                              path={`${matcher}/create-pool/pool/:poolId/step/${stepName}`}
                              render={(_props) => {
                                if (!pool || !entry || (hasFinalizedSetupFlag && loading)) {
                                  return <LoadingView />
                                }
                                return (
                                  <BracketPoolSetupCommon
                                    currentStep={stepName}
                                    steps={stepMappings}
                                    disclaimer={createPoolPageDisclaimer}
                                    isNcaaw={isNcaaw}
                                  >
                                    <AnalyticScreen
                                      feature="brackets"
                                      subfeature={`create-${stepName === "game-weights" ? "scoring" : stepName}`}
                                      title={`Create Pool - ${stepName === "game-weights" ? "Scoring" : stepName}`}
                                      deviceType={deviceType}
                                    />
                                    <PoolSetupStepForm
                                      switchStep={this.switchFormStep}
                                      currentStep={stepName}
                                      pool={pool}
                                      mutation={upsertPoolMutation}
                                      entryId={entry.id}
                                      entryName={entry.name}
                                      deviceType={deviceType}
                                      {...defaultParams}
                                      {...this.props}
                                    />
                                  </BracketPoolSetupCommon>
                                )
                              }}
                            />
                          ))}
                          <Route component={NotFound} />
                        </Switch>
                      </Transitions>
                    </PoolFormTransitionContainer>
                  </PoolFormContainer>
                )
              }}
            </Mutation>
          )}
        </Query>
      </ToggleNexusTheme>
    )
  }
}

function PoolSetupHoc(props: TPoolRouteProps) {
  const { poolData } = props
  const {
    detailedPeriod,
    detailedEntry,
    allCurrentEntries,
    isIosBrowser,
    isCbsAppWebview,
    poolId,
    poolPeriodQuery,
    hasManagerRole,
    allSegments,
    currentUser,
  } = poolData

  const segment = (detailedPeriod && allSegments.find((seg) => seg.id === detailedPeriod.segment.id)) || undefined
  if (!segment) {
    return <LoadingView />
  }
  const mergedPoolSettings = extractPoolOgProps(poolData)
  const { location } = props
  const poolIdT = poolId || getCreatePoolParams(location.pathname, location.search).poolId
  const season = segment.season
  const seasonId = season.id
  const isUpdate = !poolCreateRegexp.test(location.pathname)
  const isPoolCreate = poolCreateRegexp.test(location.pathname)
  const isLoggedIn = isCurrentUserLoggedIn(currentUser)

  const entry = allCurrentEntries.find((etr) => etr.pool.id === poolIdT) || detailedEntry || placeholderEntry
  const canEdit = hasManagerRole
  const gameType = segment.gameType

  if (!isLoggedIn && isPoolCreate && canUseDom) {
    const returnUrl = window?.location.href
    if (returnUrl) {
      const search = getAuthSearch(returnUrl, season?.productAbbrev, season?.masterProductId, isCbsAppWebview)
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
            search,
          }}
        />
      )
    }
  }
  return (
    <PoolSetup
      entry={entry as CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node}
      seasonId={seasonId}
      poolPeriodQuery={poolPeriodQuery}
      isUpdate={isUpdate}
      canEdit={canEdit}
      gameType={gameType}
      useBodyScroll={isIosBrowser && isCbsAppWebview}
      routes={props.routes}
      {...props}
      {...mergedPoolSettings}
    />
  )
}

export default PoolSetupHoc
