import React, { useContext } from "react"
import styled from "styled-components"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { Form, FormikErrors, FormikProps, withFormik, WithFormikConfig } from "formik"
import { useRouteMatch } from "react-router"
import { extractValidationError } from "../../../../common/apiErrors"
import { ENUM_MANAGER } from "../../../../common/enums"
import constants from "../../../../common/constants"
import { withoutDomain } from "../../../../common/url-utils"
import { FormSuccessStatus } from "../../../components/Form"
import Link from "../../../components/Link"
import { ICreatePoolFormProps, IPoolCreateProps } from "../PoolSetupTypes.d"
import { FormActionsContainer, FormButton } from "../styles/CommonPoolSetup.styles"
import { fontWeights, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { IUpsertBracketPoolGeneralFields, PoolFormGeneralFields, FormHelperText } from "./BracketPoolFormComponents"
import PoolDataContext, { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import Analytics from "../../../utils/analytics"
import { Platform, SapiApplication } from "../../../../__generated__/globalTypes"

export const Title = styled.div`
  color: ${bracketTheme.colors.overLight.white20};
  font-weight: ${fontWeights.bold};
  font-size: ${pxToRem(20)};
  line-height: 1;
  letter-spacing: -0.1px;
  margin-top: ${pxToRem(4)};
  margin-bottom: ${pxToRem(16)};
  text-align: center;
`

const PoolForm = styled(Form)`
  display: flex;
  flex-direction: column;

  .poolForm__actionBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;
    margin-top: 0.5rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 3rem);
      left: -1.5rem;
      display: block;
      background-color: ${bracketTheme.colors.overLight.white90};
      top: 0;
    }

    * {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`

const CreatePoolFormComponent = (props: IPoolCreateProps & FormikProps<IUpsertBracketPoolGeneralFields>) => {
  const { isSubmitting, isValid, isUpdate, isInPromoVersion, seasonId, deviceType } = props
  const isMobile = deviceType === "handheld"

  const match = useRouteMatch<any>()
  if (!match) {
    return null
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const { gameInstancesForArea, allSegments, segmentForArea } = poolData || emptyObject

  const segmentWithSeason = allSegments.find((seg) => seg.season.id === seasonId)
  if (!segmentWithSeason) {
    return null
  }

  const season = segmentWithSeason.season
  const gameInstance = gameInstancesForArea.find((gi) => gi.uid === season.gameInstance.uid)
  const isManagerAndOpen = season.isCurrent && !!gameInstance && gameInstance.poolType === ENUM_MANAGER
  const title = (isUpdate && "Name and Password") || (isManagerAndOpen && "What do you want to call your pool?") || `Pool Creation Closed`
  const baseUrl = (segmentForArea && withoutDomain(segmentForArea.baseUrl)) || "/"
  const actionContainers = (
    <FormActionsContainer>
      <FormButton as={Link} to={withoutDomain(segmentWithSeason.baseUrl)}>
        Continue Playing
      </FormButton>
    </FormActionsContainer>
  )

  const formGutters = (
    <>
      <PoolFormGeneralFields
        gameInstanceUid={season.gameInstance.uid}
        formik={props}
        isUpdate={isUpdate}
        additionalFields={
          <FormHelperText isfullWidth dataCyChildren="req-fields-lbl">
            *Required Field(s)
          </FormHelperText>
        }
        variant="fullWidth"
      />

      <div className="poolForm__actionBox">
        <StyledButton className="variant--secondary size--md" as={Link} to={baseUrl} data-cy="cancel-btn">
          Cancel
        </StyledButton>
        <Button variant="primary" withLoading loading={isSubmitting} disabled={!isValid} type="submit" data-cy="create-pool-btn">
          Create Pool
        </Button>
      </div>
    </>
  )

  return (
    <>
      {!isMobile && <Title data-cy="create-pool-label">{title}</Title>}
      <PoolForm className={`is-update--${!!isUpdate} is-in-promo-version--${!!isInPromoVersion}`}>
        {!isUpdate && !isManagerAndOpen ? actionContainers : formGutters}
      </PoolForm>
    </>
  )
}

const createPoolFormikOptions: WithFormikConfig<ICreatePoolFormProps, IUpsertBracketPoolGeneralFields> = {
  mapPropsToValues: (props) => ({
    name: props.initialName || "",
    password: props.initialPassword || "",
    usesMagicLink: props.initialUsesMagicLink ?? true,
    slogan: props.initialSlogan ?? "",
    avatarUrl: props.initialAvatarUrl ?? "",
    seasonId: props.seasonId,
    poolId: props.poolId,
    poolSettings: {
      openInvites: props.initialOpenInvites || false,
    },
  }),
  mapPropsToErrors: (props) => {
    const errors: FormikErrors<IUpsertBracketPoolGeneralFields> = {}
    if (!props.initialName?.trim()) {
      errors.name = "Please fill in your pool name"
    }
    if (!props.initialUsesMagicLink && !props.initialPassword) {
      errors.password = "Please, fill in a pool password"
    }
    return errors
  },
  validate: (values: IUpsertBracketPoolGeneralFields) => {
    const errors: FormikErrors<IUpsertBracketPoolGeneralFields> = {}
    if (!values.name?.trim()) {
      errors.name = "Please fill in your pool name"
    } else if ((values.name?.trim()?.length ?? 0) < constants.POOL_NAME_MIN_LENGTH) {
      errors.name = `Pool name must be at least ${constants.POOL_NAME_MIN_LENGTH} characters`
    }

    if (!values.usesMagicLink) {
      if (!values.password) {
        errors.password = "Please fill in your pool password"
      } else if ((values.password?.length || 0) < constants.POOL_PASSWORD_MIN_LENGTH) {
        errors.password = `Password must be at least ${constants.POOL_PASSWORD_MIN_LENGTH} characters`
      }
    }
    return errors
  },

  handleSubmit: (variables, actions) => {
    const { mutation, switchStep, isUpdate, nextStep, deviceType } = actions.props
    Analytics.trackAction("create pool", "create pool button", variables.poolSettings?.openInvites ? "open" : "closed")
    const isMobile = deviceType === "handheld"
    const platform: Platform = isMobile ? "mweb" : "desktop"
    const application: SapiApplication = isMobile ? "picksSportsAppWebview" : "picks"
    return mutation({ variables: { ...variables, platform, application } })
      .then((res) => {
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        setTimeout(() => {
          switchStep(isUpdate ? null : nextStep || "setup-choice", res?.data?.upsertPool?.pool?.id ?? "")
        }, 900)
      })
      .catch((err) => {
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
      })
  },
}

const CreatePoolForm = withFormik<ICreatePoolFormProps, IUpsertBracketPoolGeneralFields>(createPoolFormikOptions)(CreatePoolFormComponent)

export default CreatePoolForm
